import React, { useState } from "react";
import { wrapper } from 'axios-cookiejar-support';
import * as app from "../fetch/fetchApi";
import $ from 'jquery'
const axios = require('axios');
const jar = app.jar;
const client = wrapper(axios.create({ jar }));
const isBrowser = typeof window !== "undefined"
const ModalComplementa = (id) => {
   const [alerta, setAlerta] = useState('');
   var precioTotal = 0
   var cantidadGeneral = 0;
   var cantidadMenor = 0;
   const suma = () => {
      var precio = document.getElementById('precioModal').innerText;
      cantidadGeneral = cantidadGeneral + 1
      precio = precio.replace('$', '')
      let precioTotalFloat = parseFloat(precio)
      precioTotal = precioTotalFloat + precioTotal;
      document.getElementById('resumenModal').innerText = '$' + precioTotal + '.00'
      document.getElementById('cantidadVisibleModal').innerHTML = (cantidadGeneral) + ' productos'
      document.getElementById('edit-quantity').value = cantidadGeneral
      document.getElementById('edit-quantity2').value = cantidadGeneral
   }

   const resta = () => {
      var precio = document.getElementById('precioModal').innerText;
      precio = precio.replace('$', '')
      let precioTotalFloat = parseFloat(precio)
      if (cantidadGeneral > 0) {
         cantidadGeneral = cantidadGeneral - 1
         document.getElementById('edit-quantity').value = cantidadGeneral;
         document.getElementById('edit-quantity2').value = cantidadGeneral
         precioTotal = precioTotal - precioTotalFloat;
         document.getElementById('resumenModal').innerText = '$' + precioTotal + '.00'
         document.getElementById('cantidadVisibleModal').innerHTML = (cantidadGeneral) + ' productos'
      }
   }
   if (isBrowser) {
      document.addEventListener('click', function (e) {
         var id = e.target.id
         if (e.target && id.includes('myModal2')) {
            $("#resumenModal").text("$0")
            $("#cantidadVisibleModal").text("0 productos")
            $("#edit-quantity").val("0");
            $("#edit-quantity2").val("0");
            precioTotal = 0
            cantidadGeneral = 0;
         }
      })
      $("#close2").click(function () {
         $("#myModal2").css('display', 'none')
         $("#resumenModal").text("$0")
         $("#cantidadVisibleModal").text("0 productos")
         $("#edit-quantity").val("0");
         $("#edit-quantity2").val("0");
         precioTotal = 0
         cantidadGeneral = 0;

      });
   }
   const agregarCarrito = () => {
      handleLocalStorage()
      var cantidad = $("#edit-quantity2").val() > 0 ? $("#edit-quantity2").val() : $("#edit-quantity").val()
      if (cantidad > 0) {
         var modal = document.getElementById("myModal2");
         var formData = [{
            id: $("#idModal").text(), cantidad: cantidad,
         }];

         var datos = JSON.stringify(formData, 4, null)
         client.post(app.url + '/node-api/m22_insert_to_carrito/', datos, {
            withCredentials: true,
            headers: {
               crossDomain: true,
               'Content-Type': 'application/json'
            },
         })
            .then(function (response) {
               setAlerta('success')
               document.getElementById('resumenModal').innerText = '$ 0.00'
               document.getElementById('cantidadVisibleModal').innerHTML = '0 productos'
               setTimeout(() => {
                  setAlerta('')
                  modal.style.display = "none";
                  peticion()
               }, 3000);
            })
            .catch(function (error) {
               console.log(error);
               setAlerta('error')
               setTimeout(() => {
                  setAlerta('')
               }, 3000);
            });


      } else {
         setAlerta('error')
         setTimeout(() => {
            setAlerta('')
         }, 3000);
      }
   }

   async function peticion() {
      await client.get(app.url + '/node-api/m22_get_cantidad/1', {
         withCredentials: true,
         headers: {
            crossDomain: true,
            'Content-Type': 'application/json'
         },
      })
         .then(function (response) {
            localStorage.setItem('cantidadProds', response.data[0])
         })
         .catch(function (error) {
            console.log(error)
         });
   }

   const handleLocalStorage = () => {
      window.dispatchEvent(new Event("storage"));
   };

   return (
      <div id={"myModal2"} class="modal2">
         <div class="modal-content2" style={{ borderRadius: '14px' }}>
            <span id={"close2"} class="close2 d-none d-md-flex"></span>
            <div className="row paddingModal" >
               <div className="col-lg-4 col-12" >
                  <img src={''} className="d-lg-block d-none" style={{ borderRadius: '14px', height: '205px', width: '264px', objectFit: 'cover' }} id="srcModal" alt="" />
                  <div className="d-md-flex d-lg-none">
                     <img src={''} style={{ borderRadius: '14px', height: '230px', width: '330px', objectFit: 'cover' }} id="srcModal2" alt="" />
                     <div className="ms-md-3 mt-3">
                        <div class="views-field views-field-title title-container text-info mb-3"><span id="tituloModal" class="field-content title-3 titutloModalComplementa"></span></div>
                        <div class="views-field views-field-commerce-price price-container text-info mb-4">
                           <div class="field-content emphasis-v" id="precioModal"></div>
                        </div>
                        <p style={{ display: 'none' }} id="sku"></p>
                     </div>
                  </div>
               </div>
               <div className="col-lg-8 col-12">
                  <div >
                     <div className="d-lg-block d-none">
                        <div class="views-field views-field-title title-container text-info mb-3"><span id="tituloModal2" class="field-content title-3 titutloModalComplementa"></span></div>
                        <div class="views-field views-field-commerce-price price-container text-info mb-4">
                           <div class="field-content emphasis-v" id="precioModal2"></div>
                        </div>
                        <p style={{ display: 'none' }} id="sku2"></p>
                     </div>
                     <div class="views-field views-field-field-legales-cart legal-container text-info mt-3 mb-4">
                        <p class="field-content emphasis ocultar" id="letraChicaModal"></p>
                        <p id="idModal" style={{ display: 'none' }}></p>
                     </div>
                     <form class="commerce-add-to-cart commerce-cart-add-to-cart-form-598 in-stock form-container text-info mb-4" autocomplete="off" action="/atracciones/hospedaje/hotel" method="post" id="commerce-cart-add-to-cart-form-598" accept-charset="UTF-8" novalidate="novalidate">
                        <div>
                           <input type="hidden" name="product_id" value="598" tabindex="0" />
                           <input type="hidden" name="form_build_id" value="form-AFoMlehEdIHZY93es_Elf3mUz7D_teEzWnl95gOmJGc" tabindex="0" />
                           <input type="hidden" name="form_token" value="1q77IWMx7bsFAch3uhWOoG17fe7nUBSCCn1zMQVlrkA" tabindex="0" />
                           <input type="hidden" name="form_id" value="commerce_cart_add_to_cart_form_598" tabindex="0" />
                           <div id="edit-line-item-fields" class="form-wrapper"></div>
                           <div class="form-item form-type-textfield form-item-quantity d-none d-md-block"> <label for="edit-quantity" class="label-up data me-4">Cantidad </label> <button type="button" onClick={() => { resta() }} class="less button ms-md-5">-</button>
                              <input type="text" id="edit-quantity" name="quantity" value="0" size="5" maxlength="128" class="form-text label-up data" tabindex="0" />
                              <button type="button" onClick={() => { suma() }} class="more button">+</button></div>
                           <div class="form-item form-type-textfield form-item-quantity d-flex d-md-none">
                              <label for="edit-quantity" class="label-up data me-4 col-4">Cantidad </label>
                              <div className="col-8">
                                 <button type="button" onClick={() => { resta() }} class="less button ms-md-5">-</button>
                                 <input type="text" id="edit-quantity2" name="quantity" value="0" size="5" maxlength="128" class="form-text label-up data" tabindex="0" />
                                 <button type="button" onClick={() => { suma() }} class="more button">+</button></div>
                           </div>
                        </div>
                     </form>
                     <div class="d-flex mb-4">
                        <div class="description me-3"><label className={'labelReservacion'}>Resumen </label> </div>
                        <div className={'ms-0 ms-md-5 d-flex'}>
                           <label id={'cantidadVisibleModal'} className={'labelReservacion'}></label> <label className={'mx-2'} id={'resumenModal'}></label>
                        </div>
                     </div>
                     <div className="d-flex justify-content-center justify-content-md-end">
                        <button class="boton-azul" onClick={() => { agregarCarrito() }} style={{ marginTop: 0 }} id={"agregarCarritoComplementa"} name="op" value="Agregar a mi compra" type="submit">Agregar a mi compra</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         {alerta == 'success' ? <div id="add-to-cart-toast-message" style={{ visibility: 'visible' }} class="paragraph-sb">Producto agregado a carrito</div> : ''}
         {alerta == 'error' ? <div id="toast-message" class=""><span class="paragraph-sb"><strong>Error:</strong> por favor ingrese cantidad de productos.</span></div> : ''}
      </div>
   );
};

export default ModalComplementa;